body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiSlider-root .MuiSlider-thumb {
  width: 32px;
  height: 32px;
}

.MuiSlider-root .MuiSlider-rail {
  width: 16px;
}

.MuiSlider-root .MuiSlider-track {
  width: 12px;
}

.MuiSlider-root .MuiSlider-mark[data-index="0"],
.MuiSlider-root .MuiSlider-markLabel[data-index="0"] {
  bottom: 2% !important;
}

.MuiSlider-root .MuiSlider-mark[data-index="7"],
.MuiSlider-root .MuiSlider-markLabel[data-index="7"] {
  bottom: 98% !important;
}

.Plus-slider-container .MuiSlider-root .MuiSlider-mark[data-index="4"],
.Plus-slider-container .MuiSlider-root .MuiSlider-markLabel[data-index="4"] {
  bottom: 98% !important;
}
